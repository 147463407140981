.navbar_header{
  height: inherit;
  width: 10vw;
  height: 30vh;
  margin: 10px;
  margin-left: 30px;
}

.navbar_header:active {
  -webkit-filter : brightness(0);
  filter : brightness(0);
}

.spacing {
  margin-right: 20px;
  margin-left: 20px;
}
