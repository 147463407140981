@font-face {
    font-family: 'jochen_font';
    src: url('assets/fonts/montserrat/Montserrat-Light.ttf') format('truetype');
}

:root {
    --font-color: #161616;
    --font-color-bright: #ffffff;
    --standard-text: "Arial", sans-serif;
    --jochen_font: jochen_font;
}

body {
    line-height: 24px;
    font-size: 14px;
    font-family: var(--standard-text);
    /*font-family: "Roboto", sans-serif;*/
    font-weight: normal;
}

