.dataCard_card {
    width: 100%;
    border: lightgrey solid 2px;
    padding: 10px;
    margin-bottom: 20px;
}

.dataCard_card:hover {
    -webkit-transition-duration: .3s;
    transition-duration: .3s;
    background-color: #007BFF;
    color: white;
    border-color: white;
    cursor: pointer;
}

dataCard_icon {
    size: 96px;
}
