.bannerContainer {
    position: relative;
}

.bannerImg {
    width: 100%;
    max-height: 90vh;
}

.bannerImgSmall {
    width: 100%;
    max-height: 50vh;
    overflow: hidden;
}

.bannerTitle {
    font-family: var(--jochen_font);
    color: var(--font-color-bright);
}

/*TODO: Media Queries um Tablet- und Handyfähig zu werden*/
@media (max-width: 991.98px) {
    .bannerTitle {
        color: var(--font-color-bright);
        font-weight: bold;
        position: absolute;
        top: 10%;
        left: 20%;
        text-align: left;
    }

    .bannerTitle:hover {
        -webkit-transition-duration: .3s;
        transition-duration: .3s;
        cursor: pointer;
        color: #528399;
    }

    .bannerSubTitle:hover {
        -webkit-transition-duration: .3s;
        transition-duration: .3s;
        cursor: pointer;
        color: #528399;
    }

    .bannerSubTitle {
        font-family: var(--standard-text);
        color: var(--font-color-bright);
        font-weight: lighter;
        position: absolute;
        top: 50%;
        left: 20%;
        text-align: left;
    }
}


/*TODO: Media Queries um Tablet- und Handyfähig zu werden*/
@media (min-width: 992px) {
.bannerTitle {
    color: var(--font-color-bright);
    font-weight: bold;
    position: absolute;
    top: 10%;
    left: 10%;
    text-align: left;
    }

    .bannerTitle:hover {
        -webkit-transition-duration: .3s;
        transition-duration: .3s;
        cursor: pointer;
        color: #528399;
    }

    .bannerSubTitle:hover {
        -webkit-transition-duration: .3s;
        transition-duration: .3s;
        cursor: pointer;
        color: #528399;
    }

.bannerSubTitle {
    font-family: var(--standard-text);
    color: var(--font-color-bright);
    font-weight: lighter;
    position: absolute;
    top: 20%;
    left: 10%;
    text-align: left;
    }
}

