.footer{
  background-color: white;
  color: grey;
  bottom: 0;
  left: 0;
  width: 100%;
  margin-top: 7rem;

}

.line{
  border-top-style: solid;
  border-width: 0.1rem;
  margin-bottom: 1rem;
}

.p{
  text-decoration: none; 
  color : "grey"
}

.list{
  text-decoration: none;
  color: grey;
  list-style-type: none;
}

.img{
  max-height: 75px;
  height: auto;
}

.footer-link {
  color: inherit;
  text-decoration: none;
}

.footer-link:hover {
  text-decoration: underline;
}

.footer-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

