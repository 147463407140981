.apartmentList_heading {
    text-align: center;
}

.apartmentList_Container {
    margin-left: 20%;
    margin-right: 20%;
}

.apartmentList_ContainerBigScreen {
    margin-left: 4%;
    margin-right: 4%;
}


.apartmentList_text {
    margin-bottom: 30px;
    margin-top: 30px;
}

.apartmentCard_container {
    margin-bottom: 10px;
    border-radius: 5px;
    -webkit-box-shadow: 0px 0px 23px -8px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 23px -8px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 23px -8px rgba(0,0,0,0.75);
    cursor: pointer;
}

.apartmentCard_title {
    font-size: 20px;
    font-weight: bold;
    margin-top: 5px;
    text-align: center;
}

.apartmentCard_tag {

}

.apartmentCard_preis{
    text-align: left;
    margin-left: 5px;
    margin-top: 5px;
    font-weight: bold;
    color: grey;
}

.apartmentCard_button {
    margin-top: 5px;
    float: right;
    margin-bottom: 20px;
}
