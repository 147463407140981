.apartmentPage_name {
    margin-top: 20px;
    text-align: center;
    margin-bottom: 20px;
}

.apartmentPage_tag {
    color: white;
    background-color: lightslategrey;
    padding: 5px;
    margin: 10px 10px;
    border: 1px solid gray;
    border-radius: 3px;
}

.apartmentPage_tag:hover {
    cursor: pointer;
    background-color: white;
    color: grey;
    -webkit-transition-duration: .3s;
    transition-duration: .3s;
}

.btn_unverbindlicheAnfrage {
    width: 50%;
    text-align: center;
}
