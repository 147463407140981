h1 {
    text-align: center;
    vertical-align: center;
    justify-align: center;
    justify-content: center;
}

.spinner {
    text-align:center;
    vertical-align: center;
    transform: translateY(200px)
}
