.benefitsCard{
    margin-top : 1.5em
}

.benefitsCard:hover{
    -webkit-transition-duration: .3s;
    transition-duration: .3s;
    background-color: #007BFF;
    color: white;
    border-color: white;
    cursor: pointer;
}