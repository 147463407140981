.margin_large{
    margin: 4em;
}

.margin_small{
    margin: 2em;
}

.textArea_contact {
    resize: none;
}
