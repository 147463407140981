.numberDisplay {
    text-align: right;
    padding-top: 5px;
    background-color: #36363d;
    height: 2%;
    color: white;
    font-family: var(--jochen_font);
}

.numberDisplay_icon {
    text-align: right;
}

.numberDisplay_icon:hover {
    cursor: pointer;
}

.numberDisplay_number {
    text-align: left;
}

.numberDisplay_number:hover {
    cursor: pointer;
}
