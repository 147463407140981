
@media (max-width: 991.98px) {
    .home_marketingArea {
        margin-left: 5%;
        margin-right: 5%;
    }

    .marketingArea_introText {
        margin: 5%;
    }

    .marketingArea_heading {
        text-align: center  ;
    }

    .marketingArea_image {
        float: right;
    }

    .marketingArea_text {
        float: right;
        text-align: left;
    }
    .signlessLink {
        color: black;
    }

    .signlessLink:hover {
        text-decoration: none;
    }
}

@media (min-width: 992px) {
    .home_marketingArea {
        transform: translateY(-200px);
        margin-left: 5%;
        margin-right: 5%;
    }

    .marketingArea_heading {
        text-align: center;
    }

    .marketingArea_introText {
        margin: 5%;
    }

    .marketingArea_image {
        float: right;
    }

    .marketingArea_text {
        text-align: left;
    }

    .signlessLink {
        color: black;
    }

    .signlessLink:hover {
        text-decoration: none;
    }

}
