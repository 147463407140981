/*TODO: Translate prozentbasiert*/

@media (max-width: 991.98px) {
.selectBoxContainer {
    width: 90%;
    background-color: rgba(255, 255, 255, 1);
    -webkit-box-shadow: 0px 0px 48px -21px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 48px -21px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 48px -21px rgba(0,0,0,0.75);
    padding: 10px;
    border-radius: 5px;
}
.btn_suchen {
    width: 100%
}

.selectBoxContainer h1 {
    text-align:center;
    font-size: 18px;
    padding-bottom: 10px;
}

.selectBoxContainer .row {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    margin-bottom: 10px;
}
}

@media (min-width: 992px){
.selectBoxContainer {
    float: right; margin-right: 5%;
    width: 35%;
    background-color: rgba(255, 255, 255, 1);
    max-width: 400px;
    -webkit-box-shadow: 0px 0px 48px -21px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 48px -21px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 48px -21px rgba(0,0,0,0.75);
    padding: 10px;
    transform: translateY(-400px);
    z-index: 2;
    border-radius: 5px;
}
.btn_suchen {
    width: 100%
}

.selectBoxContainer h1 {
    text-align:center;
    font-size: 18px;
    padding-bottom: 10px;
}

.selectBoxContainer .row {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    margin-bottom: 10px;
}
}